import React, { useRef, useState } from 'react';

// Custom Components
import Page from 'templates/Page';
import { PrimaryWallyButton } from 'styled-component-lib/Buttons';

// Material UI
import { Box, List, ListItem, Typography } from '@material-ui/core';

export default function Backers() {
  // Load backers 10 at a time.
  const limit = 10;
  const sliceIndex = useRef(10);
  const [backerSlices, setBackerSlices] = useState([
    backers.slice(0, 10).join(', '),
  ]);

  const isDisabled = () => sliceIndex.current >= backers.length;

  const loadMoreBackers = () => {
    let currentLimit = sliceIndex.current;
    let nextLimit = currentLimit + limit;
    if (nextLimit >= backers.length) nextLimit = backers.length;
    setBackerSlices([
      ...backerSlices,
      backers.slice(currentLimit, nextLimit).join(', '),
    ]);

    sliceIndex.current = nextLimit;
  };

  return (
    <Page
      description="The Wally Shop's Kick-Starter backers."
      maxWidth="xl"
      title="Our Backers"
    >
      <Box mt={4}>
        <Typography variant="h1" gutterBottom>
          We're extremely grateful to the people who made this possible:
        </Typography>
      </Box>
      <List>
        {backerSlices.map((backerSlice, idx) => (
          <ListItem key={backerSlice[0] + idx}>
            <Typography>{backerSlice}</Typography>
          </ListItem>
        ))}
      </List>
      <br />
      <PrimaryWallyButton onClick={loadMoreBackers} disabled={isDisabled()}>
        Load More
      </PrimaryWallyButton>
    </Page>
  );
}

var backers = [
  'Bradley Kerr',
  'Lucy Phillips',
  'John',
  'Jacqueline Lin',
  'James morgan',
  'Chelsey Ann',
  'Kelly scerbo',
  'Anne Souder',
  'Dylan James',
  'Matthew G S Kerr',
  'Jessica Gross',
  'Beth',
  'Bianca khan',
  'Matthew Kerr',
  'Kathleen',
  'Eileen Vilar',
  'Joseph Morgan',
  'Radames Garau',
  'Victoria Horng',
  'Lee Phillips',
  'Adri',
  'Jeffery baird',
  'PyrrhaIphis',
  'Maxi Wells-Lorentzen',
  'Carol Chiu',
  'Kate',
  'Nichole Garcia',
  'Marines Montalvo',
  'Elena Fedina',
  'Eimy Figueroa',
  'Zoe Johnston',
  'Maria Loida',
  'Chris Vaughn',
  'Gonzalo alvarez',
  'Tiffany Derr',
  'KAYLEEN MESA',
  'kamea chayne',
  'Perry Phillips',
  'Christina',
  'Betty',
  'Diana',
  'Kristi',
  'Kevin Alvir',
  'Emma Phillips',
  'Nia Damalos',
  'Ella Kinsman',
  'Morgan Thomas',
  'Laura Arbo',
  'Clare Grady',
  'Kendall Olsen',
  'Kelsey Frandsen',
  'Caitlin Scarpelli',
  'bryan hogan',
  'Kay Bitzer',
  'Abigail Goethals',
  'Hannah',
  'Tamara Santos',
  'Artemis Gogos',
  'ANNIE',
  'Jennie chang',
  'Katie Hartmann',
  'Annalisa',
  'Sidney Blank',
  'Archer Brinkley',
  'Matthew Harold Leiwant',
  'Katie',
  'Taura Gaffney',
  'Jaz Garner',
  'Gina Cho',
  'Brendan Fung',
  'Michael Lee',
  'Zoe',
  'Morgan',
  'Haven Deutsch',
  'Maddison',
  'Emily Ide',
  'Naomi Marks',
  'Inga Guliyeva',
  'Phivos Christofides',
  'Kylie Harnen',
  'Anna Barton',
  'Andreina Himy',
  'Lauren Chitwood Schauf',
  'Jessica Bristow',
  'julie rahrig',
  'Eden Seiferheld',
  'Noa Matson',
  'Solonje Burnett',
  'Alix Omori',
  'William Remsen',
  'Anna Jacobs',
  'Anna Walewski',
  'Carolina',
  'Xiomara Guerrero',
  'Megan Lazaros',
  'Hayley',
  'Zach Phillips',
  'Margo Bradfield',
  'Larissa Wohl',
  'Sara Rastegar',
  'Julie Jacob',
  'Emily Rogers',
  'Madison Thain',
  'Sara Cochran',
  'Abby Corrigan',
  'Jeannie Carriere',
  'Leydi',
  'Scott Phillips',
  'Sara Weinreb',
  'Hanna',
  'Lena Baker',
  'Louisa',
  'Shannon Kenny',
  'Crystal PHillips',
  'Nikki Constantine',
  'Sanne Wright',
  'Darlene Gonzalez',
  'Bibi Prival',
  'Natalie',
  'Lucy',
  'Mekala Pavlin',
  'Samantha Berg',
  'Arlene',
  'Simone Anne',
  'Sarah Kappler',
  'Timothy',
  'Brady Burke',
  'Michelle Tully',
  'Will',
  'Seth',
  'Jill Carmel',
  'Caitlin Simpson',
  'Hayden Victor',
  'Brendan Burke',
  'Allison Goodwin',
  'Kristin Dee',
  'Haley Frankenberg',
  'Silvia Cohn',
  'Thea',
  'Yaiza Armbruster',
  'Margaret Marsh',
  'Natalie Bojarsky',
  'Peter Caroddo',
  'Abigail',
  'maiazw@gmail.com',
  'Sami Page',
  'Maxi Tu',
  'angelusgutmann',
  'Brian McAvoy',
  'Susanne Beck',
  'Taylor Morrison',
  'Aimee Morris',
  'Tatiana',
  'Jacob Conway',
  'Stephanie Montejo',
  'Paul Chestnut',
  'Aimee',
  'Molly Cherry',
  'Maria Broderick',
  'Doyeun Kim',
  'Ayana Renoldi-Bracco',
  'Brittany Fleit',
  'Patrick Kennelly',
  'Katie',
  'Lauren Sweeney',
  'David Aditya Soeryadjaya',
  'Claire Smither Wulsin',
  'Blair',
  'Hannah Erhart',
  'Ian',
  'Adrienne',
  'Sharon hsu',
  'Teegan Meade',
  'Avery Pearce',
  'Jacki Huntington',
  'Brad Kerr',
  'Emily Mills',
  'Willam Spitzer',
  'Caitlin Gaustad',
  'Erin',
  'Enya lee',
  'JF',
  'Cosy Perham',
  'Alkesh Thavrani',
  'Oliver DeCandido',
  'Michael Curtis',
  'Barbara Lee',
  'Sam Chipman',
  'Amanda Appelson',
  'Rachel Precious',
  'pambrown87',
  'Jenny Wirt',
  'Allison Adams',
  'Jill Chiappe',
  'Robert Watt',
  'Christina Nguyen',
  'Rachel R',
  'Ceara Teixeira',
  'Dana Reinert',
  'Tap Tap Organics',
  'Ashley Gomez',
  'Chris Tjia',
  'Henry Anton',
  'Mariana Ruiz',
  'Santiago Guzman',
  'Ari Klickstein',
  'Nic S',
  'Arielle',
  'Henry Elliman',
  'Tony Bakshi',
  'Logan',
  'Danielle Lee',
  'Ashley Rock',
  'Reid Detchon',
  'Sarah May',
  'Kate',
  'Lev',
  'Rugby Glory LLC',
  'Elizabeth Huebsch',
  'May',
  'Alison Edwards',
  'Dominic Tjia',
  'Nadine',
  'Jillian Schweitzer',
  'Mimi Akra',
  'Ida Saki',
  'Grace Han',
  'Frosti',
  'Madeleine',
  'Alexandra Jablonski',
  'Nathan Granda',
  'Terra Gutmann Gonzalez',
  'Michael Clear',
  'Aidan Lee',
  'Georgi Alice Annenberg',
  'Gabrielle bourne',
  'sarah',
  'Abby Sewell',
  'Jenni McSpadden',
  'Matthew Morikawa',
  'Detta Scarr',
  'Stephanie Kane',
  'Jess',
  'Christopher',
  'Megan',
  'anne norrick',
  'Delaney Leighton',
  'cecilia schmidt',
  'Valentina Soto',
  'Elizabeth Heller',
  'Laura Morgan',
  'chelsea vargas',
  'Allison Hackett',
  'Christine',
  'Meg Carpenter',
  'Cecily Lowe',
  'Jennifer Y. Lee',
  'Ashley',
  'Elizabeth Stone',
  'John Rowe',
  'Anisha Sisodia',
  'Ames',
  'Anna-Sophia Zingarelli-Sweet',
  'Thalia',
  'Brad',
  'Gina',
  'Kate',
  'Rebecca Adams',
  'Sam Gibson',
  'Denise Herman',
  'Ty Everett',
  'Kaitlyn Facile',
  'Brigid Maloney',
  'Amanda',
  'Anya',
  'Nelson Chu',
  'amymcglinn@gmail.com',
  'Michelle Nguyen',
  'Micaela Fernandez',
  'Virginia Salo',
  'Daniel Castillo',
  'Rose Walano',
  'Jordan',
  'Meghann McKale',
  'Ellianna Sternberg',
  'Isabella Comeaux-Morales',
  'Bill Fiddler',
  'evea',
  'Susannah Hornsby',
  'Cosimo Menotti',
  'Maëlis Jamin-Bizet',
  'Isabella Garcia',
  'Rea Colleran',
  'Kelsey Kiser',
  'Elizabeth',
  'Scott Vollmerich',
  'Alma Ocasio Miro',
  'Uyen Nguyen',
  'Arleen Paredes',
  'Avery',
  'Jonathan Yu',
  'Jessica Yu',
  'Gabriel Moscoso',
  'Melody',
  'Joakim Lehmkuhl Ferreyra',
  'Marissa Darling',
  'Samantha Symon',
  'Keith Symon',
  'Vena',
  'ping chirathivat',
  'Weifei Zheng',
  'Eric Tang',
  'Carlos Montesino',
  'Alfie',
  'wolf',
  'Kristina Cagle',
  'Tarun Gupta',
  'Dupain',
  'D Smartvlasak',
  'Anne Tang',
  'Kelsey',
  'Cody',
  'Emma Julia Vos',
  'Tifarah Canion',
  'Emily Eddington',
  'Albert Hong',
  'Liz',
  'Auri Jackson',
  'Megan Bannon',
  'Drew Register',
  'Tristin Merriman',
  'meghan allison',
  'Sally Cunningham',
  'Kelly Libby',
  'Caitlin Boyle',
  'Amanda Stevens',
  'Jennifer',
  'Lauren Lail',
  'Miriam Kaplan',
  'Dave Katz',
  'Kate McPartland',
  'Natasha Lo',
  'Kyra',
  'Trinity Connors',
  'Madison Estuesta',
  'Dana Simmons-Greco',
  'Ashley van der Laan',
  'Kendra',
  'T',
  'Larra',
  'Darlene',
  'Maureen Edmonds',
  'Ece',
  'Natalia Lynch',
  'Claire Moore',
  'Meg Heim',
  'Thomas Wachter',
  'Adina H',
  'Angela Schwartz',
  'Samuel',
  'Nikole Morris',
  'Amelia Salsgiver',
  'Kaleb Barrett',
  'Chelsea Michelson',
  'Leah white',
  'Regina Scott',
  'Miggie Herrera',
  'Megan',
  'Julia',
  'Leigh Bangs',
  'Mary Dickson',
  'Lily Burdick',
  'Katrina Ulnick',
  'Spencer Quinn',
  'Purilan',
  'Bethany Schoon',
  'Misia Kowanda',
  'Francis Tjia',
  'Xavier Ames',
  "Death's Head Studio, LLC",
  'Anna Marie Fitzgerald',
  'Elizabeth Kerr',
  'Veronica Tao',
  'Autoproofer',
  'Beth',
  'Christine Santariello',
  'Socheata',
  'Sarah Dalton',
  'Matt Drake',
  'Christa',
  'julie paik',
  'Stacey Eck',
  'Jennifer Peterson',
  'Robin Utz',
  'Isabel',
  'Christopher Tang',
  'TL Jergesen',
  'Asif Bhavnagri',
  'Jill',
  'Darcy Stephens',
  'Kelly Rytel',
  'Sarah Wutzler',
  'Elena Gasol',
  'Coral Elizabeth Hayward',
  'Vaughn Bergen',
  'Franco Quintana',
  'Samantha Bryan',
  'Erin Denny',
  'Katie',
  'Katie',
  'Dominika & Will',
  'BUMFI',
  'Miriam Fogel',
  'Peggy Pease',
  'Rachel',
  'Annie Wang',
  'Miranda',
  'Amy Pierce',
  'Madeleine Ghillany-Lehar',
  'avery regen',
  'Darlene Lopez',
  'Kathleen Francis',
  'Marissa Weaver',
  'Travis Kwee',
  'Nicket Uttarwar',
  'Jackson',
  'Joshua Miller',
  'Steve Wystrach',
  'Andrew Ding',
  'Meryl Dhir',
  'Cassie Wallender',
  'STICKFX MASKS',
  'Melissa Jones',
  'Elizabeth',
  'Jo',
  'Sindy Martinez',
  'Jakki',
  'Alex Hezik',
  'Sophie',
  'Ariel',
  'Benedicte Godet',
  'karina',
  'Julianna Rodríguez Dolsen',
  'Jonathan Teper',
  'Nathalia ramos',
  'lindsay schmidt',
  'Allyson Adriatico Tiojanco',
  'Sofia Gimenez Burlo',
  'Matthew Simon',
  'Anna Sorokina',
  'Michelle Strong',
  'Julia',
  'Margot',
  'Susan Stiehm',
  'julia wang',
  'Jamie Roth',
  'Adriana Madrigal',
  'Elizabeth King',
  'Eliza Foster',
  'Larry Yu',
  'Katheryn Bwye',
  'Christine McGuire',
  'Sille Eva Bertelsen',
  'Jackie Roche',
  'Sebastian Valdes',
  'Natasha Sequeira',
  'Mariah Mulhare',
  'Sara Barclay',
  'Charmaine Ricacho',
  'alexis',
  'Salima Saunders',
  'John Charles',
  'Laura',
  'rhys',
  'Kirsten Kurth',
  'Valeria Romero',
  'John Titus',
  'Karen Villalba-Acosta',
  'Carolynn Box',
  'JC',
  'Azmi Abdulhadi',
  'Madeline Zilelian',
  'Rachel Kim',
  'STACY IGE',
  'scobo',
  'JD',
  'Gabriela Rousseau',
  'Andrei Matorin',
  'Fatimah Hoque',
  'Sonia James',
  'Cody',
  'William',
  'Natalie',
  'Gabriela',
  'Edmond Koo',
  'Krystle Duheme',
  'Mohamed Samaha',
  'Natalie Salman',
  'Joe Chen',
  'Anna',
  'Katie Huffman',
  'Sadie',
  'Emily Long',
  'Scott McCracken',
  'Patrick Pasco',
  'Hyunjin Kim',
  'Gavin Jonan',
  "Harriet O'Brien",
  'William Tan',
  'Michelle Van',
  'Angelica',
  'Ling Huang',
  'ruth ann p',
  'Kristen Dickerson',
  'bobbo',
  'Colby Lowrey',
  'Annie Austin',
  'Parker Reposa, Sage Hill',
  'Jane',
  'Connor Hudson',
  'wickham boyle',
  'Alex Sandstrom',
  'Shelby Walker',
  'Altenon',
  'Hope Khan',
  'Ana Mejia',
  'Lily Rosenthal',
  'Peter Jostrand',
  'Sarah Schmidt',
  'Caroline Glaenzer',
  'Ryan Campbell',
  'Betsy',
  'The Creative Fund by BackerKit',
  'Kerry',
  'Tamara',
  'Umar',
  'Ray Furey',
  'Dhutnick',
  'Martin Baker',
  'Yolanda',
  'Yoly',
  'Rat Spike',
  'Phoebe Lapine',
  'Olivia Claparols',
  'Molly',
  'Meg Jones',
  'Lilly',
  'Danielle',
  'Amir Khan',
  'Allison Sturgill',
  'Devin Cruz',
  'Laura Sayer',
  'erana k',
  'Esther Izquierdo',
  'Janet',
  'Niki',
  'Janine Beydoun',
  'Saga Blane',
  'Nicki Wallace',
  'Anneka',
  'Skye Volpi',
  'Nathalie',
  'kate dunn',
  'Fegan Carter',
  'Ian L',
  'Armita',
  'Andrew',
  'Ann Phillips',
  'Lisa Lim',
  'Amanda W',
  'Yan',
  'Christa Peters',
  'Marisella Prada',
  'Nick Nardacci',
  'Thananya Charoenchitmun',
  'Stephen Horn',
  'Joan Yaffe',
  'Erick Rodriguez',
  'Susan Courtney',
  'Alexa Gantous',
  'nicole calderon',
  'Elizabeth Tjia',
  'Itati Calderon',
  'RANDOLPH E C SALAZAR',
  'Kevin Ibasco',
  'Isabela Boerger',
  'Gabriela',
  'Santiago Sanchez',
  'Sam',
  'Loriet',
  'Marian Daniells',
  'Amanda Winarko',
  'Venancio Maloto',
  'Glenda Maloto',
  'sophia osorio',
  'Christina Black',
  'Nicole',
  'Linda Rachmat',
  'Olivia',
  'Helena Poe',
  'Belkis',
  'Paola Moreno',
  'Olivia Joseph',
  'Lee Taylor-Penn',
  'Roberto Labrada',
  'Ashley Soto',
  'Andres Hernandez',
  'Jason Valdez',
  'Juan',
  'Nic.T',
  'Eugene Mahr',
  'Sarah',
  'Jessica Reilly',
  'Marian Abbruzzese',
  'Jenny',
  'Anna Riehl',
  'Kelly Teacher',
  'grace',
  'Zoey & Cassie',
  'Ivy Tzur',
  'Jacqueline Chia',
  'Michele Soeryadjaya',
  'Kristin',
  'Erin Clarkson',
  'Jazmine Rogers',
  'Alexandra',
  'Guru Soeryadjaya',
  'Angela Rollins',
  'Jennifer Shirazi',
  'Nicholas Bernstein',
  'Katy Patton',
  'Amy Braden',
  'Nelson Chen',
  'Samarya Gutierrez',
  'Kia Scott',
  'Sandi Rahaju',
  'Emily Munoz',
  'Eduardo N. Oncena Jr.',
  'Juliet dyer',
  'Jeannette Schmid',
  'Elsa Chiao',
  'Daniel Renteria',
  'Judith Soeryadjaya',
  'Edwin Soeryadjaya',
  'Sophie Pearce',
  'Serin Choi',
  'Maria João Caxide',
  'Ryan Falkner',
  'Grouphug',
  'Marcelle Clements',
  'kathryn winder',
  'Ilene rafii',
  'Joshua Brau',
  'Dan Murphy',
  'Rebecca Barr',
  'Zaisheng Dai',
  'mckinzy',
  'Louisa Burch',
  'Erin',
  'Rachelle',
  'James',
  'Sophia Rodriguez',
  'Kim Garcia',
  'Cynthia Jones',
];
