import React, {
  Component,
  lazy,
  Suspense,
  useCallback,
  useEffect,
} from 'react';

// Services & utilities
import { logPageView } from 'services/google-analytics';
import { connect } from 'utils';

// Config
import { APP_URL } from 'config';

// npm components
import { Box, Container, Typography } from '@material-ui/core';

// Custom Components
import AddonFirstModal from 'common/AddonFirstModal';
import CategoryCard from './CategoryCard';
import CategoriesList from './CategoriesList';
import Filters from './ProductTop/Filters';
import Hero from './Hero';
import Product from './Product';
import ProductList from './ProductList';

// import ProductWithPackaging from '../ProductWithPackaging';
import SchedulePickupForm from 'forms/user-nav/SchedulePickupForm';
import { useStores } from 'hooks/mobx';

// Cookies
import { useCookies } from 'react-cookie';

// React Router
import { Link } from 'react-router-dom';

// Styled Components
import { PrimaryWallyButton } from 'styled-component-lib/Buttons';

const ProductModal = lazy(() => import('modals/ProductModalV2'));

class Mainpage extends Component {
  constructor(props) {
    super(props);

    this.userStore = this.props.store.user;
    this.uiStore = this.props.store.ui;
    this.routing = this.props.store.routing;
    this.modalStore = this.props.store.modal;
    this.modalV2 = this.props.store.modalV2;
    this.productStore = this.props.store.product;
    this.checkoutStore = this.props.store.checkout;
    this.zipStore = this.props.store.zip;
    this.packagingUnitStore = this.props.store.packagingUnit;

    this.state = {
      deliveryTimes: this.checkoutStore.deliveryTimes,
      sidebar: [],
      categoryTypeMode: 'limit',
      sortType: 'times_bought',
    };

    this.id = this.props.match.params.id;
  }

  componentDidMount() {
    // Store page view in google analytics
    const { location } = this.routing;
    const { match } = this.props;
    logPageView(location.pathname);
    this.userStore.getStatus(true).then((status) => {
      if (match && match.path === '/schedule-pickup') {
        this.modalV2.open(<SchedulePickupForm />);
      }

      if (window.location.pathname.split('/')[1] === 'packaging') {
        if (
          [
            '5e0e488c3f26046cc60195f6',
            '5e0e488c3f26046cc60195f4',
            '5e0e488c3f26046cc60195f3',
            '5e0e488c3f26046cc60195f2',
          ].includes(this.props.match.params.id)
        ) {
          window.location.href = `https://the-wally-shop-app.s3.us-east-2.amazonaws.com/ambassador-pdf/${this.props.match.params.id}.pdf`;
        } else {
          this.packagingUnitStore
            .getPackagingUnit(this.props.match.params.id)
            .then((unit) => {
              if (unit.packaging_type_id === '5e0e45220ec2446bcfeed983') {
                window.location.href = `https://the-wally-shop-app.s3.us-east-2.amazonaws.com/ambassador-pdf/welcome-letter.pdf?qr_ref=${this.props.match.params.id}`;
              } else {
                if (unit.product_id) {
                  this.handleProductModal(unit.product_id);
                } else {
                  this.routing.push(`/?qr_ref=${this.props.match.params.id}`);
                }
              }
            })
            .catch((e) =>
              console.error('Failed to load product displayed: ', e),
            );
        }
      } else {
        if (!status) {
          this.routing.push('/');
        } else {
          // this.checkoutStore.getDeliveryTimes();
          this.loadData();
          const { mainFirst, mainSecond } = this.userStore.flags || {};
          !mainFirst && this.modalStore.toggleModal('mainFirst');
          mainFirst && !mainSecond && this.modalStore.toggleModal('mainSecond');
        }
      }
    });
  }

  loadData() {
    const id = this.props.match.params.id;
    this.id = id;

    if (this.id === 'buyagain') {
      this.productStore
        .getHistoricalProducts(this.userStore.getHeaderAuth())
        .catch((e) => console.error('Failed to load historical products: ', e));
    } else {
      let categoryTypeMode = 'all';

      this.setState({ categoryTypeMode });

      const deliveryData = this.userStore.getDeliveryParams();

      this.productStore.getAdvertisements();
      // this.productStore.getCategories();
      this.productStore
        .getProductDisplayed(id, deliveryData, this.userStore.getHeaderAuth())
        .then((data) => {
          this.userStore.adjustDeliveryTimes(
            data.delivery_date,
            this.state.deliveryTimes,
          );
          this.setState({ sidebar: this.productStore.sidebar });
        })
        .catch((e) => console.error('Failed to load product displayed: ', e));
    }
  }

  componentDidUpdate() {
    const id = this.props.match.params.id;
    if (this.id !== id) {
      this.loadData();
    }
  }

  handleProductModal = async (product_id) => {
    try {
      await this.productStore.showModal(product_id, null);
      this.modalV2.open(
        <Suspense
          fallback={<Typography variant="h1">Loading product...</Typography>}
        >
          <ProductModal />
        </Suspense>,
        'right',
        'md',
      );
    } catch (error) {
      console.error(error);
    }
  };

  handleSort = (type) => {
    this.setState({ sortType: type });
  };

  sortByType = (a, b) => {
    switch (this.state.sortType) {
      case 'times_bought':
        return b.times_bought - a.times_bought;
      case 'last_ordered':
        return Date(a.last_ordered) <= Date(b.last_ordered);
      case 'by_name':
        return a.product_name.localeCompare(b.product_name);
      default:
        return 0;
    }
  };

  render() {
    const { sidebar } = this.state;

    const id = this.props.match.params.id;
    const ads1 = this.productStore.ads1 ? this.productStore.ads1 : null;
    const ads2 = this.productStore.ads2 ? this.productStore.ads2 : null;

    return (
      <div className="App">
        <div className="product-content">
          <Container maxWidth="xl">
            <div className="row ">
              {/* Featured Brands */}
              <div className="col-xl-3 col-lg-3 d-xl-block d-lg-block d-md-none d-sm-none d-none">
                <div className="product-content-left">
                  <div className="product-content-left-scroll">
                    <div className="mb-4">
                      <Filters />
                    </div>
                    <CategoriesList selectedId={id} list={sidebar} />
                    <br />
                    <div>
                      {ads1 && <img src={APP_URL + ads1.image} alt="" />}
                    </div>
                    <br />
                  </div>
                </div>
              </div>

              {id === 'buyagain' ? (
                <div className="col-xl-9 col-lg-9 col-md-12">
                  <div className="product-content-right">
                    <div className="product-breadcrumb">
                      <h2>Buy Again</h2>
                      <div className="filters">
                        <div className="filters-title">Sort:</div>
                        <div className="filters-values as-sort">
                          <ul>
                            <li
                              className={
                                this.state.sortType === 'times_bought'
                                  ? 'active'
                                  : ''
                              }
                              onClick={() => this.handleSort('times_bought')}
                            >
                              Most Bought
                            </li>
                            <li
                              className={
                                this.state.sortType === 'last_ordered'
                                  ? 'active'
                                  : ''
                              }
                              onClick={() => this.handleSort('last_ordered')}
                            >
                              Recently Ordered
                            </li>
                            <li
                              className={
                                this.state.sortType === 'by_name'
                                  ? 'active'
                                  : ''
                              }
                              onClick={() => this.handleSort('by_name')}
                            >
                              A-Z
                            </li>
                          </ul>
                        </div>
                      </div>
                      <hr />
                    </div>

                    <div className="row">
                      {this.productStore.historical_products.length ? (
                        this.productStore.historical_products
                          .sort((a, b) => this.sortByType(a, b))
                          .map((product, index) => (
                            <Product
                              key={index}
                              product={product}
                              deliveryTimes={this.state.deliveryTimes}
                            />
                          ))
                      ) : (
                        <h2>No Orders Yet</h2>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-xl-9 col-lg-9 col-md-12">
                  <div className="product-content-right">
                    {/* {this.props.location.pathname.split('/')[1] ===
                    'packaging-blank' ? (
                      <ProductWithPackaging
                        packagingId={this.props.match.params.id}
                      />
                    ) : ( */}
                    <>
                      <Hero />
                      {ads2 && (
                        <img
                          src={APP_URL + ads2.image}
                          className="img-fluid"
                          alt=""
                        />
                      )}

                      {this.state.categoryTypeMode === 'limit' ? (
                        <div className="row">
                          {this.productStore.main_display.map(
                            (category, index) => (
                              <CategoryCard key={index} category={category} />
                            ),
                          )}
                        </div>
                      ) : (
                        this.productStore.main_display.map(
                          (category, index) => (
                            <ProductList
                              key={index}
                              display={category}
                              mode={this.state.categoryTypeMode}
                              deliveryTimes={this.state.deliveryTimes}
                            />
                          ),
                        )
                      )}
                    </>
                  </div>
                </div>
              )}
            </div>
          </Container>
        </div>
        <ClosingNotification />
        <AddonFirstModal />
      </div>
    );
  }
}

function ClosingNotification() {
  const { dialog: dialogStore, user: userStore } = useStores();

  const { isUser = false } = userStore;
  const [cookies, setCookies] = useCookies(['readClosingNotification']);
  let { readClosingNotification } = cookies;

  const handleRead = useCallback(() => {
    setCookies('readClosingNotification', true);
    dialogStore.close();
  }, [dialogStore, setCookies]);

  useEffect(() => {
    if (isUser && !readClosingNotification) {
      dialogStore.open(
        <>
          <Typography component="h2" gutterBottom variant="h3">
            To The Wally Shop Community
          </Typography>
          <Typography gutterBottom>
            The Wally Shop will be closing operations as of April 16th. Although
            this is a difficult time for us, we wanted to share how it has truly
            been our pleasure to both introduce some of the community to the
            reusable lifestyle as well as support those who have been on this
            journey a lot longer than us. Over the past three years, we have
            been amazed and also touched by the amount of support we’ve received
            from our initial clientele in Brooklyn and then the rest of the
            country. We believe reusables offer a real solution to our waste
            epidemic and cannot wait to see what the future holds for a circular
            economy.
          </Typography>
          <Typography gutterBottom>
            To ensure we do right by you, we would like to share the following
            key points:
          </Typography>
          <ul>
            <li>
              <Typography gutterBottom>
                If you would like to return any packaging, please hand them off
                to UPS by April 12th. This is to ensure they arrive in time at
                our warehouse and give our warehouse team sufficient time to
                process them.
              </Typography>
            </li>
            <li>
              <Typography gutterBottom>
                We will continue to accept new orders until April 16th. Please,
                please repurpose, reuse or recycle any packaging received. All
                Wally jars are made of a single material and can be recycled
                with other plastics.
              </Typography>
            </li>
            <li>
              <Typography gutterBottom>
                The last day to request the redemption of your packaging balance
                is April 19. We have created a form to make this process as
                smooth as possible. If you’d like to request the redemption of
                your balance,{' '}
                <Link
                  to="/packaging-credit-refund"
                  onClick={() => dialogStore.close()}
                >
                  {' '}
                  please fill out this form.
                </Link>
              </Typography>
            </li>
          </ul>
          <Typography gutterBottom>
            **Finally, to ensure the least amount of waste, Products will be
            heavily discounted and all packaging deposits waived**
          </Typography>
          <Typography gutterBottom>
            We would also like to thank our partners brands - please continue
            showing them your support as they too believed in a reusable future.
            You can find all of these wonderful brands online on our website.
          </Typography>
          <Typography gutterBottom>
            Thank you again, the Wally community, for all of the support you’ve
            shown these past few years. It has been an incredible experience.
          </Typography>
          <Typography component="p" gutterBottom variant="h6">
            Until we meet again,
            <br />
            The Wally Shop
          </Typography>
          <Box display="flex" justifyContent="center">
            <PrimaryWallyButton onClick={handleRead}> OK </PrimaryWallyButton>
          </Box>
        </>,
        'sm',
      );
    }
  }, [handleRead, dialogStore, readClosingNotification]);

  return null;
}

export default connect('store')(Mainpage);
